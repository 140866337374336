<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <h1>{{user.fullname}}</h1>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-expansion-panels hover v-model="panel" multiple>
          <!-- Player -->
          <v-expansion-panel v-if="user">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Favorited Players
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="grey lighten-3">
              <loading-bar :value="loading.includes('players')"></loading-bar>
              <v-expand-transition>
                <player-cards-list
                  v-if="loading.includes('players') || (filteredPlayers && filteredPlayers.length)"
                  :players="filteredPlayers"
                  :loading="loading.includes('players')"
                  :canHideFooter="true"
                ></player-cards-list>
                <v-alert v-else type="info" :value="true" prominent class="mt-4 mb-0" text>
                  You don't have any players favorited
                </v-alert>
              </v-expand-transition>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Events -->
          <v-expansion-panel>
            <v-expansion-panel-header color="color1 color1Text--text" class="title bt">
              Favorited Events
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="grey lighten-3">
              <loading-bar :value="loading.includes('tournaments')"></loading-bar>
              <tournament-list
                v-if="loading.includes('tournaments') || summaries && summaries.length"
                :tournaments="summaries"
                :loading="loading.includes('tournaments')"
                :ads="[]"
                :sortDesc="[true]"
                :itemCountStart="4"
              ></tournament-list>
              <v-alert v-else type="info" :value="true" prominent class="mt-4 mb-0" text>
                You don't have any tournaments favorited
              </v-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- Player -->
          <v-expansion-panel v-if="user">
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Players I Took Notes On
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content color="grey lighten-3">
              <loading-bar :value="loading.includes('notes')"></loading-bar>
              <v-expand-transition>
                <player-cards-list
                  v-if="loading.includes('notes') || (notes && notes.length)"
                  :players="notes"
                  :loading="loading.includes('notes')"
                  :canHideFooter="true"
                ></player-cards-list>
                <v-alert v-else type="info" :value="true" prominent class="mt-4 mb-0" text>
                  You don't have any players with notes
                </v-alert>
              </v-expand-transition>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import PlayerProfile from '@/classes/PlayerProfile'
import PlayerCardsList from '@/components/Player/PlayerCardsList'
import Summary from '@/classes/TournamentSummary'
import TournamentList from '@/components/Tournament/TournamentList'

export default {
  data () {
    return {
      panel: [0, 1, 2],
      loading: [],
      players: null,
      summaries: null,
      notes: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    playerIds () {
      return this.user.bookmarks ? this.user.bookmarks.filter(f => f.type === 'player').map(m => m.id) : []
    },
    filteredPlayers () {
      return this.players && this.players.filter(f => this.playerIds.includes(f.id))
    }
  },
  components: {
    PlayerCardsList,
    TournamentList
  },
  methods: {
    getPlayers () {
      this.loading.push('players')
      this.$VBL.get.playerList('?bookmarks=true', null)
        .then(r => {
          this.players = r.data.players.map(m => new PlayerProfile(null, m))
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'players') })
    },
    getNotes () {
      this.loading.push('notes')
      this.$VBL.get.playerList('?notes=true', null)
        .then(r => {
          this.notes = r.data.players.map(m => new PlayerProfile(null, m))
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'notes') })
    },
    getTourneys () {
      this.loading.push('tournaments')
      const query = 'bookmarks=true'
      this.$VBL.tournament.getAllSummaries(query)
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = this.loading.filter(f => f !== 'tournaments') })
    }
  },
  created () {
    this.getPlayers()
    this.getTourneys()
    this.getNotes()
  }
}
</script>

<style scoped>
.bt {
  border-top: 1px solid white !important
}
>>> .v-expansion-panel__header {
  margin-bottom: 2px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
</style>
